import { Market } from "@/models/market/Market";
import { Request } from "@/models/request/Request";
import {
    MarketRequest,
    MarketRequestState
} from "@/models/request/MarketRequest";
import { UpdateEntity } from "@/models/UpdateEntity";
import Vue from "vue";
import { MutationTree } from "vuex";
import { REQUEST_COLUMN_SETTINGS, RequestState } from ".";
import { RequestListItem } from "@/models/request/list/RequestListItem";
import { RequestFormConfig } from "@/models/request/RequestFormConfig";
import { RequestInfo } from "@/models/request/RequestInfo";
import { Question } from "@/models/Question";
import { RequestModelFilter } from "@/models/request/RequestVehicleModelFilter";
import { RequestGridConfig } from "@/models/request/RequestGridConfig";
import localStorageService from "@/services/localStorageService";

export enum RequestMutations {
    SetErrors = "setErrors",
    ClearRequest = "clearRequest",
    AddNewMarket = "addNewMarket",
    RemoveMarket = "removeMarket",
    UpdateMarket = "updateMarket",
    UpdateRequest = "updateRequest",
    UpdateQuestions = "updateQuestions",
    SetRequests = "setRequests",
    UpdateFormConfig = "updateFormConfig",
    SetInfo = "setInfo",
    SetLoading = "setLoading",
    SetEditingState = "setEditingState",
    SetFormConfigTemplate = "setFormConfigTemplate",
    UpdateFormConfigTemplate = "updateFormConfigTemplate",
    UpdateModelFilter = "updateModelFilet",
    CreateRequest = "createRequest",
    SetRequest = "setRequest",
    RemoveRequest = "removeRequest",
    AddAttachment = "addAttachment",
    RemoveAttachment = "removeAttachment",
    ClearAttachments = "clearAttachments",
    UpdateRequestGridConfig = "updateRequestGridConfig"
}

const mutations: MutationTree<RequestState> = {
    [RequestMutations.SetErrors](state, payload: string[] | null): void {
        state.errors = payload;
    },
    [RequestMutations.ClearRequest](state) {
        state.errors = [];
        state.isNew = true;
        state.requests = [];
        state.request = {
            supplyScenarios: [],
            requestTypeId: null,
            customerId: null,
            ikamId: null,
            requestFormConfig: null,
            requestVehicleModelFilter: null,
            marketRequests: [],
            customerDeadlineDate: new Date(),
            marketDeadlineDate: new Date(),
            questions: [],
            internationalBonus: null,
            attachments: []
        };
        state.isEdited = false;
        state.newAttachments = [];
        state.removedAttachmentIds = [];
    },
    [RequestMutations.AddNewMarket](state, payload: Market) {
        const requestToMarket: MarketRequest = {
            marketId: payload.marketId,
            market: payload,
            state: MarketRequestState.Open,
            usersTo: [],
            usersCc: []
        };
        state.request.marketRequests.push(requestToMarket);
        state.isEdited = true;
    },
    [RequestMutations.RemoveMarket](state, payload: MarketRequest) {
        const index = state.request.marketRequests.indexOf(payload);
        if (index > -1) {
            state.request.marketRequests.splice(index, 1);
            state.isEdited = true;
        }
    },
    [RequestMutations.UpdateMarket](
        state,
        payload: UpdateEntity<MarketRequest>
    ) {
        const index = state.request.marketRequests.indexOf(payload.key);
        if (index !== -1) {
            const request = state.request.marketRequests[index];
            const fields = payload.fields;

            Vue.set(state.request.marketRequests, index, {
                ...request,
                ...fields
            });
            state.isEdited = true;
        }
    },
    [RequestMutations.UpdateRequest](state, payload: Partial<Request>) {
        if (state.request) {
            state.request = {
                ...state.request,
                ...payload
            };
            state.isEdited = true;
        }
    },
    [RequestMutations.UpdateQuestions](state, payload: Question[]) {
        state.request.questions = payload;
        state.isEdited = true;
    },
    [RequestMutations.SetRequests](state, payload: RequestListItem[]) {
        state.requests = payload;
        state.isEdited = false;
    },
    [RequestMutations.UpdateFormConfig](
        state,
        payload: Partial<RequestFormConfig>
    ) {
        if (state.request.requestFormConfig) {
            state.request.requestFormConfig = {
                ...state.request.requestFormConfig,
                ...payload
            };
            state.isEdited = true;
        }
    },
    [RequestMutations.SetInfo](state, payload: RequestInfo) {
        state.info = payload;
    },
    [RequestMutations.SetLoading](state, payload: boolean) {
        state.isLoading = payload;
    },
    [RequestMutations.SetFormConfigTemplate](
        state,
        payload: RequestFormConfig
    ) {
        state.formConfigTemplate = payload;
    },
    [RequestMutations.UpdateFormConfigTemplate](
        state,
        payload: RequestFormConfig
    ) {
        // Check for disabled columns when update request type
        if (state.request.requestFormConfig) {
            const config = state.request.requestFormConfig;
            config.comments = config.comments && payload.comments;
            config.optionDiscount =
                config.optionDiscount && payload.optionDiscount;
            config.price = config.price && payload.price;
            config.sumPrice = config.sumPrice && payload.sumPrice;
            config.validFrom = config.validFrom && payload.validFrom;
            config.validTill = config.validTill && payload.validTill;
            config.vehicleDiscountPercent =
                config.vehicleDiscountPercent && payload.vehicleDiscountPercent;
            config.vehicleDiscountValue =
                config.vehicleDiscountValue && payload.vehicleDiscountValue;
            config.vehiclePriceList =
                config.vehiclePriceList && payload.vehiclePriceList;
        }
    },
    [RequestMutations.UpdateModelFilter](
        state,
        payload: Partial<RequestModelFilter>
    ) {
        if (state.request.requestVehicleModelFilter) {
            state.request.requestVehicleModelFilter = {
                ...state.request.requestVehicleModelFilter,
                ...payload
            };
            state.isEdited = true;
        }
    },
    [RequestMutations.CreateRequest](state, payload: number) {
        state.isNew = false;
        state.request.requestId = payload;
    },
    [RequestMutations.SetRequest](state, payload: Request) {
        state.isNew = false;
        state.request = payload;
    },
    [RequestMutations.RemoveRequest](state, payload: number) {
        state.requests = state.requests.filter((r) => r.requestId !== payload);
    },
    [RequestMutations.AddAttachment](state, payload: File): void {
        state.newAttachments.push(payload);
    },
    [RequestMutations.RemoveAttachment](state, payload: string | number): void {
        if (state.request) {
            const attachmentIndex = state.request.attachments.findIndex(
                (a) => a.id === payload
            );
            if (attachmentIndex > -1) {
                state.request.attachments.splice(attachmentIndex, 1);
                state.removedAttachmentIds.push(payload as number);
                return;
            }
        }
        const newAttachmentIndex = state.newAttachments.findIndex(
            (a) => a.name === payload
        );
        if (newAttachmentIndex > -1) {
            state.newAttachments.splice(newAttachmentIndex, 1);
        }
    },
    [RequestMutations.ClearAttachments](state): void {
        state.newAttachments = [];
        state.removedAttachmentIds = [];
    },
    [RequestMutations.SetEditingState](state, payload: boolean) {
        state.isEdited = payload;
    },
    [RequestMutations.UpdateRequestGridConfig](
        state,
        payload: Partial<RequestGridConfig>
    ) {
        state.requestGridConfig = { ...state.requestGridConfig, ...payload };
        localStorageService.save(
            REQUEST_COLUMN_SETTINGS,
            state.requestGridConfig
        );
    }
};

export default mutations;
