import { CountryUCPSource } from "@/models/CountryUCPSource";
import { MutationTree } from "vuex";
import { CountryUCPSourceState } from ".";
import { CountryUCPSourceUpdateDTO } from "@/models/CountryUCPSourceUpdateDTO";

export enum CountryUCPSourceMutations {
    SetCountryUCPSources = "addCountryUCPSources",
    UpdateCountryUCPSource = "updateCountryUCPSource",
    ClearUpdatedCountryUCPSources = "clearUpdatedCountryUCPSources",
    SetErrors = "setErrors",
    SetImportStatus = "setImportingModels",
    ChangeImportingResultPopupVisible = "changeImportingResultPopupVisible",
    SetLoading = "setLoading"
}

const mutations: MutationTree<CountryUCPSourceState> = {
    [CountryUCPSourceMutations.SetCountryUCPSources](
        state,
        payload: CountryUCPSource[]
    ): void {
        state.countryUCPSources = payload;
    },
    [CountryUCPSourceMutations.SetErrors](
        state,
        payload: string[] | null
    ): void {
        state.errors = payload;
    },
    [CountryUCPSourceMutations.UpdateCountryUCPSource](
        state,
        updatedItem: CountryUCPSourceUpdateDTO
    ): void {
        const index = state.updatedCountryUCPSources.findIndex(
            (item) => item.countryUCPSourceId === updatedItem.countryUCPSourceId
        );

        if (index !== -1) {
            state.updatedCountryUCPSources.splice(index, 1, updatedItem);
        } else {
            state.updatedCountryUCPSources.push(updatedItem);
        }
    },
    [CountryUCPSourceMutations.ClearUpdatedCountryUCPSources](state): void {
        state.updatedCountryUCPSources = [];
    },
    [CountryUCPSourceMutations.SetLoading](state, payload: boolean) {
        state.isLoading = payload;
    },
    [CountryUCPSourceMutations.ChangeImportingResultPopupVisible](
        state,
        payload: boolean
    ) {
        state.importingResultPopupVisible = payload;
    },
    [CountryUCPSourceMutations.SetImportStatus](state, payload: boolean) {
        state.importingModels = payload;
    }
};

export default mutations;
