import { ModuleTree } from "vuex";
import { RootState } from "..";
import request from "./request";
import market from "./market";
import customer from "./customer";
import model from "./model";
import user from "./user";
import marketRequest from "./marketRequest";
import account from "./account";
import role from "./role";
import maintenanceBanner from "./maintenanceBanner";
import tip from "./tip";
import countryUCPSource from "./countryUCPSource";

const modules: ModuleTree<RootState> = {
    request,
    market,
    customer,
    model,
    user,
    marketRequest,
    account,
    role,
    maintenanceBanner,
    tip,
    countryUCPSource
};

export default modules;
