import { ActionTree, Commit } from "vuex";
import { CountryUCPSourceMutations } from "./mutations";
import { makeRequest } from "@/services/requestUtils";
import countryUCPSourcesApi from "@/api/countryUCPSourcesApi";
import { CountryUCPSourceState } from ".";
import { RootState } from "@/store";

export enum CountryUCPSourceActions {
    GetCountryUCPSources = "getCountryUCPSources",
    UpdateCountryUCPSource = "updateCountryUCPSource",
    ExecuteImportVehicles = "executeImportVehicles",
    GetImportStatus = "getImportStatus"
}

async function makeModuleRequest<T>(
    commit: Commit,
    callback: () => Promise<T>,
    onSuccess?: (result: T) => void
): Promise<void> {
    await makeRequest(
        commit,
        CountryUCPSourceMutations.SetErrors,
        CountryUCPSourceMutations.SetLoading,
        callback,
        onSuccess
    );
}

const actions: ActionTree<CountryUCPSourceState, RootState> = {
    async [CountryUCPSourceActions.GetCountryUCPSources]({
        commit
    }): Promise<void> {
        await makeModuleRequest(commit, async () => {
            const countryUCPSources =
                await countryUCPSourcesApi.getCountryUCPSources();
            commit(
                CountryUCPSourceMutations.SetCountryUCPSources,
                countryUCPSources
            );
        });
    },
    async [CountryUCPSourceActions.UpdateCountryUCPSource]({
        commit,
        state,
        dispatch
    }): Promise<void> {
        await makeModuleRequest(commit, async () => {
            await countryUCPSourcesApi.updateCountryUCPSources(
                state.updatedCountryUCPSources
            );

            commit(CountryUCPSourceMutations.ClearUpdatedCountryUCPSources);
            await dispatch(CountryUCPSourceActions.GetCountryUCPSources);
        });
    },
    async [CountryUCPSourceActions.ExecuteImportVehicles]({
        commit
    }): Promise<void> {
        await makeRequest(
            commit,
            CountryUCPSourceMutations.SetErrors,
            CountryUCPSourceMutations.SetImportStatus,
            async () => await countryUCPSourcesApi.executeImportVehicles()
        );
    },
    async [CountryUCPSourceActions.GetImportStatus]({ commit }): Promise<void> {
        await makeModuleRequest(commit, async () => {
            const response = await countryUCPSourcesApi.getImportStatus();
            const isRunning = response.isRunning;
            commit(CountryUCPSourceMutations.SetImportStatus, isRunning);
        });
    }
};

export default actions;
