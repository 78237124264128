
import Vue from "vue";
import { Component, Emit, PropSync } from "vue-property-decorator";
import { DxPopup } from "devextreme-vue/popup";
import { DxButton } from "devextreme-vue";

@Component({
    components: { DxPopup, DxButton }
})
export default class ImportStartPopup extends Vue {
    @PropSync("visible", { type: Boolean, required: true })
    public popupVisible!: boolean;

    @Emit()
    confirm(): void {
        this.popupVisible = false;
    }
}
