import { CountryUCPSource } from "@/models/CountryUCPSource";
import { RootState } from "@/store";
import { GetterTree } from "vuex";
import { CountryUCPSourceState } from ".";

export enum CountryUCPSourceGetters {
    CountryUCPSources = "countryUCPSources",
    IsLoading = "isLoading",
    ImportingModels = "importingModels",
    ImportingResultPopupVisible = "importingResultPopupVisible",
    Errors = "errors"
}

const getters: GetterTree<CountryUCPSourceState, RootState> = {
    [CountryUCPSourceGetters.CountryUCPSources](state): CountryUCPSource[] {
        return state.countryUCPSources;
    },
    [CountryUCPSourceGetters.IsLoading](state): boolean {
        return state.isLoading;
    },
    [CountryUCPSourceGetters.ImportingModels](state): boolean {
        return state.importingModels;
    },
    [CountryUCPSourceGetters.ImportingResultPopupVisible](state): boolean {
        return state.importingResultPopupVisible;
    },
    [CountryUCPSourceGetters.Errors](state): string[] | null {
        return state.errors;
    }
};

export default getters;
