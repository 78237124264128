/* eslint-disable no-unused-vars */
export enum Routes {
    Home = "/",
    Login = "/account/login",
    RequestPasswordReset = "/account/request-password-reset",
    PasswordReset = "/account/password-reset",
    AccountActivation = "/account/activate",
    TwoFactorActivation = "/account/2fa",
    CreateRequest = "/request/create",
    Markets = "/markets",
    EditMarket = "/markets/edit/",
    CreateMarket = "/markets/create",
    Customers = "/customers",
    EditCustomer = "/customers/edit/",
    CreateCustomer = "/customers/create",
    RequestsInProgress = "/requests/in-progress",
    Request = "/requests/",
    ArchivedRequest = "/requests/archived/",
    Users = "/users",
    EditUser = "/users/edit/",
    CreateUser = "/users/create",
    Archive = "/archive",
    Drafts = "/drafts",
    Draft = "/draft/",
    Error = "/error/",
    CountryUCPSources = "/country-ucp-sources/"
}
