
import BaseButton from "@/components/BaseButton";
import CountryUCPSourceList from "@/components/CountryUCPSourceList";
import ImportStartPopup from "@/components/ImportStartPopup";
import { ImportStatusCheckingInterval } from "@/consts/timerIntervals";
import { countryUCPSourceModule } from "@/store/modules/countryUCPSource";
import { CountryUCPSourceActions } from "@/store/modules/countryUCPSource/actions";
import { CountryUCPSourceGetters } from "@/store/modules/countryUCPSource/getters";
import { CountryUCPSourceMutations } from "@/store/modules/countryUCPSource/mutations";
import { DxProgressBar } from "devextreme-vue";
import Vue from "vue";
import Component from "vue-class-component";

interface ParentComponentRefs {
    countryUCPSourceList: InstanceType<typeof CountryUCPSourceList>;
}

@Component({
    components: {
        BaseButton,
        CountryUCPSourceList,
        ImportStartPopup,
        DxProgressBar
    },
    methods: {
        ...countryUCPSourceModule.mapActions({
            getCountryUCPSources: CountryUCPSourceActions.GetCountryUCPSources,
            updateCountryUCPSources:
                CountryUCPSourceActions.UpdateCountryUCPSource,
            executeImportVehicles:
                CountryUCPSourceActions.ExecuteImportVehicles,
            getImportStatus: CountryUCPSourceActions.GetImportStatus
        }),
        ...countryUCPSourceModule.mapMutations({
            changeImportingResultPopupVisible:
                CountryUCPSourceMutations.ChangeImportingResultPopupVisible
        })
    },
    computed: {
        ...countryUCPSourceModule.mapGetters({
            isLoading: CountryUCPSourceGetters.IsLoading,
            importingModels: CountryUCPSourceGetters.ImportingModels
        })
    }
})
export default class CountryUCPSources extends Vue {
    private readonly getCountryUCPSources!: () => Promise<void>;
    private readonly executeImportVehicles!: () => Promise<void>;
    private readonly updateCountryUCPSources!: () => Promise<void>;
    private readonly changeImportingResultPopupVisible!: (
        payload: boolean
    ) => void;
    private readonly getImportStatus!: () => Promise<void>;

    public importStartPopupVisible = false;
    public progressValue = 0;

    protected readonly isLoading!: boolean;
    protected readonly importingModels!: boolean;
    private importStatusIntervalId: number | null = null;

    async created(): Promise<void> {
        await this.getCountryUCPSources();
        await this.checkImportStatus();
        this.setupImportStatusTimer();
    }

    beforeDestroy(): void {
        // Clear the interval when the component is destroyed
        if (this.importStatusIntervalId !== null) {
            clearInterval(this.importStatusIntervalId);
        }
    }

    private async checkImportStatus(): Promise<void> {
        await this.getImportStatus();
    }

    private setupImportStatusTimer(): void {
        this.importStatusIntervalId = window.setInterval(async () => {
            await this.checkImportStatus();
        }, ImportStatusCheckingInterval);
    }

    showImportPopup(): void {
        this.importStartPopupVisible = true;
    }

    get dataGrid(): ParentComponentRefs | null {
        return this.$refs as unknown as ParentComponentRefs;
    }

    async saveChanges(): Promise<void> {
        await this.dataGrid?.countryUCPSourceList.saveGridEdits();

        await this.updateCountryUCPSources();
    }

    async startImport(): Promise<void> {
        await this.executeImportVehicles();
        this.changeImportingResultPopupVisible(true);
    }
}
