
import { Component } from "vue-property-decorator";
import Vue from "vue";
import { DxButton, DxPopup } from "devextreme-vue";
import { countryUCPSourceModule } from "@/store/modules/countryUCPSource";
import { CountryUCPSourceMutations } from "@/store/modules/countryUCPSource/mutations";
import { CountryUCPSourceGetters } from "@/store/modules/countryUCPSource/getters";

@Component({
    components: {
        DxPopup,
        DxButton
    },
    methods: {
        ...countryUCPSourceModule.mapMutations({
            changeImportingResultPopupVisible:
                CountryUCPSourceMutations.ChangeImportingResultPopupVisible
        })
    },
    computed: {
        ...countryUCPSourceModule.mapGetters({
            importingResultPopupVisible:
                CountryUCPSourceGetters.ImportingResultPopupVisible
        })
    }
})
export default class VehicleImportResultPopup extends Vue {
    private readonly importingResultPopupVisible!: boolean;
    private readonly changeImportingResultPopupVisible!: (
        payload: boolean
    ) => void;

    get showPopup(): boolean {
        return this.importingResultPopupVisible;
    }
    set showPopup(payload: boolean) {
        this.changeImportingResultPopupVisible(payload);
    }
}
