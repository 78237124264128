
import Vue from "vue";
import Component from "vue-class-component";
import VehicleImportResultPopup from "@/components/VehicleImportResultPopup";
import { countryUCPSourceModule } from "@/store/modules/countryUCPSource";
import { CountryUCPSourceGetters } from "@/store/modules/countryUCPSource/getters";

@Component({
    components: {
        VehicleImportResultPopup
    },
    computed: {
        ...countryUCPSourceModule.mapGetters({
            importingResultPopupVisible:
                CountryUCPSourceGetters.ImportingResultPopupVisible
        })
    }
})
export default class GlobalPopupWrapper extends Vue {
    private readonly importingResultPopupVisible!: boolean;

    get isVehicleImportResultPopupVisible(): boolean {
        return this.importingResultPopupVisible;
    }
}
