import { CountryUCPSource } from "@/models/CountryUCPSource";
import api from ".";
import { CountryUCPSourceUpdateDTO } from "@/models/CountryUCPSourceUpdateDTO";
import { ImportStatus } from "@/models/ImportStatus";

async function executeImportVehicles(): Promise<void> {
    return await api.post("/ucp-sources/execute-import");
}

async function getCountryUCPSources(): Promise<CountryUCPSource[]> {
    const response = await api.get("/ucp-sources");
    return response.data;
}

async function updateCountryUCPSources(
    countryUCPSources: CountryUCPSourceUpdateDTO[]
): Promise<void> {
    return await api.put("/ucp-sources", countryUCPSources);
}

async function getImportStatus(): Promise<ImportStatus> {
    const response = await api.get("/ucp-sources/import-status");
    return response.data;
}

export default {
    executeImportVehicles,
    getCountryUCPSources,
    updateCountryUCPSources,
    getImportStatus
};
