
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import {
    DxColumn,
    DxDataGrid,
    DxLoadPanel,
    DxPager,
    DxPaging,
    DxEditing,
    DxHeaderFilter
} from "devextreme-vue/data-grid";
import { countryUCPSourceModule } from "@/store/modules/countryUCPSource";
import { CountryUCPSourceGetters } from "@/store/modules/countryUCPSource/getters";
import { CountryUCPSource } from "@/models/CountryUCPSource";
import dxDataGrid, { RowUpdatedEvent } from "devextreme/ui/data_grid";
import { CountryUCPSourceMutations } from "@/store/modules/countryUCPSource/mutations";
import { CountryUCPSourceUpdateDTO } from "@/models/CountryUCPSourceUpdateDTO";

@Component({
    components: {
        DxDataGrid,
        DxColumn,
        DxLoadPanel,
        DxPaging,
        DxPager,
        DxEditing,
        DxHeaderFilter
    },
    computed: {
        ...countryUCPSourceModule.mapGetters({
            countryUCPSources: CountryUCPSourceGetters.CountryUCPSources
        })
    },
    methods: {
        ...countryUCPSourceModule.mapMutations({
            updateCountryUCPSource:
                CountryUCPSourceMutations.UpdateCountryUCPSource
        })
    }
})
export default class CountryUCPSourceList extends Vue {
    protected readonly countryUCPSources!: CountryUCPSource[] | null;
    protected readonly updateCountryUCPSource!: (
        payload: CountryUCPSourceUpdateDTO
    ) => void;

    localCountryUCPSources: CountryUCPSource[] = [];

    get dataGrid(): dxDataGrid<CountryUCPSource, unknown> | null {
        const dataGrid = this.$refs.datagrid as DxDataGrid;
        return dataGrid.instance ? dataGrid.instance : null;
    }

    async saveGridEdits(): Promise<void> {
        await this.dataGrid?.saveEditData();
    }

    @Prop({ type: Boolean, default: false })
    public readonly loading!: boolean;

    onRowUpdated(e: RowUpdatedEvent): void {
        const updatedItem: CountryUCPSource = { ...e.data };

        if (updatedItem.source) {
            updatedItem.source = updatedItem.source.trim();
        }
        if (updatedItem.modelMatrix) {
            updatedItem.modelMatrix = updatedItem.modelMatrix.trim();
        }
        const updateDTO = {
            countryUCPSourceId: updatedItem.countryUCPSourceId,
            source: updatedItem.source,
            modelMatrix: updatedItem.modelMatrix
        } as CountryUCPSourceUpdateDTO;

        this.updateCountryUCPSource(updateDTO);
    }

    @Watch("countryUCPSources", { immediate: true, deep: true })
    onCountryUCPSourcesChange(newVal: CountryUCPSource[]): void {
        if (newVal) {
            // Create a deep copy to avoid mutating the Vuex store directly
            this.localCountryUCPSources = newVal.map((item) => ({ ...item }));
        }
    }
}
