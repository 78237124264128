import { CountryUCPSource } from "@/models/CountryUCPSource";
import { RootState } from "@/store";
import { createNamespacedHelpers, Module } from "vuex";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import { CountryUCPSourceUpdateDTO } from "@/models/CountryUCPSourceUpdateDTO";

export interface CountryUCPSourceState {
    countryUCPSources: CountryUCPSource[];
    updatedCountryUCPSources: CountryUCPSourceUpdateDTO[];
    isLoading: boolean;
    importingModels: boolean;
    importingResultPopupVisible: boolean;
    errors: string[] | null;
}

const countryUCPSourceState: CountryUCPSourceState = {
    countryUCPSources: [],
    updatedCountryUCPSources: [],
    isLoading: false,
    importingModels: false,
    importingResultPopupVisible: false,
    errors: null
};

const countryUCPSource: Module<CountryUCPSourceState, RootState> = {
    namespaced: true,
    state: countryUCPSourceState,
    actions: actions,
    getters: getters,
    mutations: mutations
};

export const countryUCPSourceModule =
    createNamespacedHelpers("countryUCPSource");

export default countryUCPSource;
