import marketRequestApi from "@/api/marketRequestApi";
import { RequestState as State } from "@/models/request/Request";
import { RequestAdminDataUpdateDTO } from "@/models/request/RequestAdminDataUpdateDTO";
import { makeRequest, saveAttachment } from "@/services/requestUtils";
import { saveData } from "@/services/saveData";
import { RootState } from "@/store";
import { ActionTree, Commit } from "vuex";
import { RequestState } from ".";
import { MarketRequestState } from "../marketRequest";
import { RequestGridConfig } from "@/models/request/RequestGridConfig";
import { createRequestListItemDataSource } from "@/services/data/requestListItemDataSource";
import { RequestMutations } from "./mutations";
import { RequestAdminDetailsUpdateDTO } from "@/models/request/RequestAdminDetailsUpdateDTO";

export enum RequestActions {
    LoadRequests = "loadRequests",
    LoadRequestById = "loadRequestById",
    LoadRequestsByState = "loadRequestsByState",
    LoadRequestsByMarket = "loadRequestsByMarket",
    LoadInfo = "loadInfo",
    LoadRequestFormConfigTemplate = "loadRequestFormConfigTemplate",
    SaveRequest = "createRequest",
    DeleteDraft = "deleteRequest",
    SendRequest = "sendRequest",
    FinalizeRequest = "finalizeRequest",
    Export = "export",
    Clone = "clone",
    UpdateRequestAdminData = "updateRequestAdminData",
    UpdateRequestAdminDetails = "updateRequestAdminDetails",
    UpdateRequestAndSendMessage = "updateRequestAndSendMessage",
    ExportDashboard = "exportDashboard",
    CreateRequestDataSource = "createRequestDataSource"
}

async function saveRequestAttachment(
    requestId: number,
    attachments: File[],
    removeAttachmentsId: number[],
    onError: (errors: string[]) => void
): Promise<void> {
    await saveAttachment(
        requestId,
        attachments,
        removeAttachmentsId,
        marketRequestApi.uploadAttachment,
        marketRequestApi.deleteAttachment,
        (_, errors) => onError(errors)
    );
}

async function makeModuleRequest<T>(
    commit: Commit,
    callback: () => Promise<T>,
    onSuccess?: (result: T) => void
): Promise<void> {
    await makeRequest(
        commit,
        RequestMutations.SetErrors,
        RequestMutations.SetLoading,
        callback,
        onSuccess
    );
}

const actions: ActionTree<RequestState, RootState> = {
    async [RequestActions.LoadRequests]({ commit }) {
        commit(RequestMutations.SetRequests, []);
        await makeModuleRequest(
            commit,
            () => marketRequestApi.getRequests(),
            (requests) => commit(RequestMutations.SetRequests, requests)
        );
    },
    async [RequestActions.LoadRequestsByState]({ commit }, payload: State) {
        commit(RequestMutations.SetRequests, []);
        await makeModuleRequest(
            commit,
            () => marketRequestApi.getRequestsByState(payload),
            (requests) => commit(RequestMutations.SetRequests, requests)
        );
    },
    async [RequestActions.LoadRequestsByMarket](
        { commit },
        payload: { marketId: number; marketRequestState?: MarketRequestState }
    ) {
        commit(RequestMutations.SetRequests, []);
        await makeModuleRequest(
            commit,
            () =>
                marketRequestApi.getRequestsByMarket(
                    payload.marketId,
                    payload.marketRequestState
                ),
            (requests) => commit(RequestMutations.SetRequests, requests)
        );
    },
    async [RequestActions.LoadInfo]({ commit }, marketId?: number) {
        await makeModuleRequest(
            commit,
            () => marketRequestApi.getRequestInfo(marketId),
            (info) => commit(RequestMutations.SetInfo, info)
        );
    },
    async [RequestActions.LoadRequestFormConfigTemplate](
        { commit },
        payload: number
    ) {
        await makeModuleRequest(
            commit,
            () => marketRequestApi.getConfigByRequestType(payload),
            (config) => {
                commit(RequestMutations.SetFormConfigTemplate, config);
                commit(RequestMutations.UpdateFormConfigTemplate, config);
            }
        );
    },
    async [RequestActions.SaveRequest]({ commit, state }) {
        const request = state.request;
        let requestFunc: () => Promise<void>;
        if (state.isNew) {
            requestFunc = () =>
                makeModuleRequest(
                    commit,
                    () => marketRequestApi.createRequest(request),
                    (requestId) =>
                        commit(RequestMutations.CreateRequest, requestId)
                );
        } else {
            requestFunc = () =>
                makeModuleRequest(commit, () =>
                    marketRequestApi.saveRequest(request)
                );
        }

        await requestFunc();

        if (request.requestId) {
            await saveRequestAttachment(
                request.requestId,
                state.newAttachments,
                state.removedAttachmentIds,
                (errors) => commit(RequestMutations.SetErrors, errors)
            );
            const updatedRequest = await marketRequestApi.getRequestById(
                request.requestId
            );
            commit(RequestMutations.SetRequest, updatedRequest);
            commit(RequestMutations.ClearAttachments);
        }
    },
    async [RequestActions.LoadRequestById]({ commit }, payload: number) {
        makeModuleRequest(
            commit,
            () => marketRequestApi.getRequestById(payload),
            (request) => commit(RequestMutations.SetRequest, request)
        );
    },
    async [RequestActions.DeleteDraft]({ commit }, payload: number) {
        await makeModuleRequest(
            commit,
            () => marketRequestApi.deleteDraft(payload),
            () => commit(RequestMutations.RemoveRequest, payload)
        );
    },
    async [RequestActions.SendRequest]({ commit, dispatch, state }) {
        await dispatch(RequestActions.SaveRequest);
        if (state.request?.requestId) {
            const requestId = state.request.requestId;
            await makeModuleRequest(commit, () =>
                marketRequestApi.sendRequest(requestId)
            );
        }
    },
    async [RequestActions.FinalizeRequest](
        { commit },
        payload: { requestId: number; message: string }
    ) {
        await makeModuleRequest(
            commit,
            () =>
                marketRequestApi.finalizeRequest(
                    payload.requestId,
                    payload.message
                ),
            () => commit(RequestMutations.RemoveRequest, payload.requestId)
        );
    },
    async [RequestActions.Export]({ commit }, payload: number) {
        if (payload) {
            await makeModuleRequest(
                commit,
                () => marketRequestApi.exportRequest(payload),
                (file) => saveData(file.data, file.filename)
            );
        }
    },
    async [RequestActions.Clone]({ commit }, payload: number) {
        await makeModuleRequest(
            commit,
            () => marketRequestApi.cloneRequest(payload),
            (request) => commit(RequestMutations.SetRequest, request)
        );
    },
    async [RequestActions.UpdateRequestAdminData](
        { commit },
        payload: RequestAdminDataUpdateDTO
    ) {
        await makeModuleRequest(commit, () =>
            marketRequestApi.updateRequestAdminData(payload)
        );
    },
    async [RequestActions.UpdateRequestAdminDetails](
        { commit },
        payload: RequestAdminDetailsUpdateDTO
    ) {
        await makeModuleRequest(commit, () =>
            marketRequestApi.updateRequestAdminDetails(payload)
        );
    },
    async [RequestActions.UpdateRequestAndSendMessage](
        { commit, dispatch, state },
        payload: string
    ): Promise<void> {
        const request = state.request;
        await dispatch(RequestActions.SaveRequest);
        if (request.requestId) {
            const requestId = request.requestId;
            await makeModuleRequest(commit, () =>
                marketRequestApi.sendRequestUpdateMessage(requestId, payload)
            );
        }
    },
    async [RequestActions.ExportDashboard](
        { commit },
        requestGridConfig: RequestGridConfig
    ) {
        await makeModuleRequest(
            commit,
            () => marketRequestApi.exportDashboard(requestGridConfig),
            (file) => saveData(file.data, file.filename)
        );
    },
    [RequestActions.CreateRequestDataSource](
        { commit, state },
        payload: {
            state?: string | null;
            marketId?: number | null;
            marketRequestState?: string | null;
        }
    ) {
        return createRequestListItemDataSource(
            commit,
            state,
            payload.state ?? null,
            payload.marketId ?? null,
            payload.marketRequestState ?? null
        );
    }
};

export default actions;
