import marketRequestApi from "@/api/marketRequestApi";
import { MarketRequestState as MarketState } from "@/models/request/MarketRequest";
import { RequestCopyTermsDTO } from "@/models/request/RequestCopyTermsDTO";
import { RequestFormValue } from "@/models/request/RequestFormValue";
import { makeRequest, saveAttachment } from "@/services/requestUtils";
import { saveData } from "@/services/saveData";
import { RootState } from "@/store";
import { AxiosError } from "axios";
import { ActionTree, Commit } from "vuex";
import { MarketRequestState } from ".";
import { MarketRequestMutations } from "./mutations";
import { handleApiError } from "@/services/errorHandlingUtils";
import notify from "devextreme/ui/notify";
import { convertErrorObjectToString } from "@/services/errorHandlingUtils";

export enum MarketRequestActions {
    LoadRequestById = "loadRequestById",
    SaveMarketRequest = "saveMarketRequest",
    LoadMarketRequestDataById = "loadMarketRequestDataById",
    SendToHQMarketRequest = "sendToHQMarketRequest",
    SendMessageToHeadquarters = "sendMessageToHeadquarters",
    SendMessageToMarketUsers = "sendMessageToMarketUsers",
    ApproveMarketRequest = "approveMarketRequest",
    WithdrawApprovalMarketRequest = "withdrawApprovalMarketRequest",
    RequestUpdateMarketRequest = "requestUpdateMarketRequest",
    FinalizeRequest = "finalizeRequest",
    Export = "export",
    Import = "import",
    CopyTerms = "copyTerms",
    LoadMarketRequestDetails = "loadMarketRequestDetails"
}

async function saveRequestAttachment(
    requestId: number,
    attachments: File[],
    removeAttachmentsId: number[],
    onError: (error: string[]) => void
): Promise<void> {
    await saveAttachment(
        requestId,
        attachments,
        removeAttachmentsId,
        marketRequestApi.uploadMarketRequestAttachment,
        marketRequestApi.deleteMarketRequestAttachment,
        (_, errors) => onError(errors)
    );
}
async function makeModuleRequest<T>(
    commit: Commit,
    callback: () => Promise<T>,
    onSuccess?: (result: T) => void,
    onFailure?: (error: AxiosError) => void
): Promise<void> {
    await makeRequest(
        commit,
        MarketRequestMutations.SetErrors,
        MarketRequestMutations.SetLoading,
        callback,
        onSuccess,
        onFailure
    );
}

const actions: ActionTree<MarketRequestState, RootState> = {
    async [MarketRequestActions.LoadRequestById](context, payload: number) {
        await makeModuleRequest(
            context.commit,
            () => marketRequestApi.getRequestInfoById(payload),
            (request) => {
                context.commit(MarketRequestMutations.SetRequest, request);
                context.commit(
                    MarketRequestMutations.SetSelectedMarketRequest,
                    request.marketRequests[0] ?? null
                );
            },
            (error) => handleApiError(error, [404])
        );
    },
    async [MarketRequestActions.LoadMarketRequestDataById](
        context,
        payload: number
    ) {
        if (context.state.request) {
            const requestId = context.state.request.requestId;
            await makeModuleRequest(
                context.commit,
                () => marketRequestApi.getMarketRequestData(payload, requestId),
                (data) =>
                    context.commit(MarketRequestMutations.SetValuesData, data)
            );
        }
    },
    async [MarketRequestActions.SaveMarketRequest](
        { state, commit, dispatch },
        values: RequestFormValue[]
    ) {
        if (state.selectedMarketRequest?.marketRequestId) {
            const marketRequest = state.selectedMarketRequest;

            const selectedIds = state.valuesData.selectedModels
                .filter(
                    (m) =>
                        !state.modelsInfo.removedModelIds.includes(
                            m.vehicleModelId
                        )
                )
                .map((m) => m.vehicleModelId);
            selectedIds.push(...state.modelsInfo.addedModelIds);
            values = values.filter((v) =>
                selectedIds.includes(v.vehicleModelId)
            );

            await makeModuleRequest(commit, () =>
                marketRequestApi.saveMarketRequestValues(
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    marketRequest.marketRequestId!,
                    {
                        answers: marketRequest.answers ?? [],
                        values: values,
                        addedVehicleIds: state.modelsInfo.addedModelIds,
                        removedVehicleIds: state.modelsInfo.removedModelIds
                    }
                )
            );

            const errors = state.errors;
            await dispatch(
                MarketRequestActions.LoadMarketRequestDataById,
                marketRequest.marketRequestId
            );
            if (errors) {
                commit(MarketRequestMutations.SetErrors, errors);
            }

            if (marketRequest.marketRequestId) {
                await saveRequestAttachment(
                    marketRequest.marketRequestId,
                    state.newAttachments,
                    state.removedAttachmentIds,
                    (errors) => commit(MarketRequestMutations.SetErrors, errors)
                );
                commit(MarketRequestMutations.ClearAttachmentInfo);
            }
            commit(MarketRequestMutations.CleanModelsInfo);
        }
    },
    async [MarketRequestActions.SendToHQMarketRequest](
        { commit, state },
        payload: string
    ) {
        if (state.selectedMarketRequest?.marketRequestId) {
            const marketRequestId = state.selectedMarketRequest.marketRequestId;
            await makeModuleRequest(
                commit,
                () =>
                    marketRequestApi.sendToHQMarketRequest(
                        marketRequestId,
                        payload
                    ),
                () =>
                    commit(
                        MarketRequestMutations.SetMarketRequestState,
                        MarketState.Committed
                    )
            );
        }
    },
    async [MarketRequestActions.ApproveMarketRequest](
        { commit, state },
        payload: string
    ) {
        if (state.selectedMarketRequest?.marketRequestId) {
            const marketRequestId = state.selectedMarketRequest.marketRequestId;
            await makeModuleRequest(
                commit,
                () =>
                    marketRequestApi.approveMarketRequest(
                        marketRequestId,
                        payload
                    ),
                () =>
                    commit(
                        MarketRequestMutations.SetMarketRequestState,
                        MarketState.Approved
                    )
            );
        }
    },
    async [MarketRequestActions.WithdrawApprovalMarketRequest](
        { commit, state },
        payload: string
    ) {
        if (state.selectedMarketRequest?.marketRequestId) {
            const marketRequestId = state.selectedMarketRequest.marketRequestId;
            await makeModuleRequest(
                commit,
                () =>
                    marketRequestApi.withdrawApprovalMarketRequest(
                        marketRequestId,
                        payload
                    ),
                () =>
                    commit(
                        MarketRequestMutations.SetMarketRequestState,
                        MarketState.Approved
                    )
            );
        }
    },
    async [MarketRequestActions.SendMessageToHeadquarters](
        { commit, state },
        message: string
    ) {
        if (state.selectedMarketRequest?.marketRequestId) {
            const marketRequestId = state.selectedMarketRequest.marketRequestId;

            await makeModuleRequest(
                commit,
                () =>
                    marketRequestApi.sendMessageToHeadquartersAsync(
                        marketRequestId,
                        message
                    ),
                () => commit(MarketRequestMutations.UpdateMarketRequestAnswer)
            );
        }
    },
    async [MarketRequestActions.SendMessageToMarketUsers](
        { commit, state },
        payload: { message: string; selectedUserIds: number[] | null }
    ) {
        if (state.selectedMarketRequest?.marketRequestId) {
            const marketRequestId = state.selectedMarketRequest.marketRequestId;
            await makeModuleRequest(
                commit,
                () =>
                    marketRequestApi.sendMessageToMarketUsersAsync(
                        marketRequestId,
                        payload.message,
                        payload.selectedUserIds
                    ),
                () => notify("Messages successfully sended", "success", 5000),
                (error) => {
                    notify(
                        convertErrorObjectToString(error.response?.data) ??
                            "An error occurred during sending message to market users",
                        "error",
                        10000
                    );
                }
            );
        }
    },
    async [MarketRequestActions.RequestUpdateMarketRequest](
        { commit, state },
        payload: string
    ) {
        if (state.selectedMarketRequest?.marketRequestId) {
            const marketRequestId = state.selectedMarketRequest.marketRequestId;
            await makeModuleRequest(
                commit,
                () =>
                    marketRequestApi.requestUpdateMarketRequest(
                        marketRequestId,
                        payload
                    ),
                () =>
                    commit(
                        MarketRequestMutations.SetMarketRequestState,
                        MarketState.UpdateRequested
                    )
            );
        }
    },
    async [MarketRequestActions.FinalizeRequest](
        { commit, state },
        payload: string
    ) {
        if (state.request?.requestId) {
            const requestId = state.request.requestId;
            await makeModuleRequest(commit, () =>
                marketRequestApi.finalizeRequest(requestId, payload)
            );
        }
    },
    async [MarketRequestActions.Export]({ state, commit }) {
        if (state.request?.requestId) {
            const requestId = state.request.requestId;
            await makeModuleRequest(
                commit,
                () => marketRequestApi.exportRequest(requestId),
                (file) => saveData(file.data, file.filename)
            );
        }
    },
    async [MarketRequestActions.Import](
        { commit, dispatch },
        payload: {
            marketRequestId: number;
            requestId: number;
            file: File;
        }
    ) {
        commit(MarketRequestMutations.SetImportResult, null);
        await makeModuleRequest(
            commit,
            () =>
                marketRequestApi.importRequest(
                    payload.marketRequestId,
                    payload.requestId,
                    payload.file
                ),
            (result) => {
                commit(MarketRequestMutations.SetImportResult, result);
            }
        );
        await dispatch(
            MarketRequestActions.LoadMarketRequestDataById,
            payload.marketRequestId
        );
    },
    async [MarketRequestActions.CopyTerms](
        { commit, dispatch, state },
        payload: RequestCopyTermsDTO
    ) {
        await makeModuleRequest(commit, () =>
            marketRequestApi.copyTerms(payload)
        );
        if (state.selectedMarketRequest) {
            await dispatch(
                MarketRequestActions.LoadMarketRequestDataById,
                state.selectedMarketRequest.marketRequestId
            );
        }
        commit(MarketRequestMutations.CleanModelsInfo);
    },
    async [MarketRequestActions.LoadMarketRequestDetails](
        context,
        marketRequestId: number
    ) {
        await makeModuleRequest(
            context.commit,
            () => marketRequestApi.loadMarketRequestDetails(marketRequestId),
            (marketRequest) => {
                context.commit(
                    MarketRequestMutations.UpdateMarketRequest,
                    marketRequest
                );
                context.commit(
                    MarketRequestMutations.SetSelectedMarketRequest,
                    marketRequest
                );
            }
        );
    }
};

export default actions;
